<template>
  <div id="app">
    <!-- <router-view/> -->
          <el-container>
        <el-header height="190px">
          <app-header></app-header>
        </el-header>
        <el-main>
          <router-view></router-view>
        </el-main>
        <el-footer height="206px" style="padding: 0;">
          <app-footer></app-footer>
        </el-footer>
      </el-container>
  </div>
</template>
<script>
import AppHeader from "@/components/header";
import AppFooter from "@/components/footer"
 export default {
      components:{
        AppHeader,
        AppFooter
      },
        data() {
            return {

            }
        },
        created(){
      
        },
        methods:{
          
        },
        mounted(){

        }
    }
</script>

<style lang="less">
#app {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

  .el-container { height: 100%; }  
  .el-footer{     
    height: auto;
    overflow: hidden;
    color: #d4dbe5;
    line-height: 24px;
    text-align: center;
    padding: 29px 0 45px;
    background-color: #004887; } 
  .el-main { 
    background-color: #fff;
    // z-index: 10;
    padding: 0 !important;
  }
  .el-header{
    padding: 0 !important;
  }

</style>
