import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './api'
import ElementUI from 'element-ui';
// import less from 'less'

import './assets/css/common.css'
import './assets/css/style.css'
import './assets/css/animate.css'


import 'element-ui/lib/theme-chalk/index.css';

Vue.prototype.$http = axios

Vue.config.productionTip = false
Vue.use(ElementUI);
// Vue.use(less)

new Vue({
  router,
  store,
  axios,
  render: h => h(App)
}).$mount('#app')

