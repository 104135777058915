import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const Home =()=> import('@/views/home/Home.vue') //首页
const Abouts =()=> import('@/views/about/about.vue')//关于商会
const information =()=> import('@/views/information/information.vue')//商会资讯
const member =()=> import('@/views/member/member.vue')//会员之家
const Shellone =()=> import('@/views/Shellone/Shellone.vue')//会员风采
const college =()=> import('@/views/college/college.vue')//商学院
const cooperation =()=> import('@/views/cooperation/cooperation.vue')//战略合作
const Business =()=> import('@/views/Business/Business.vue')//商机在握
const protectRights =()=> import('@/views/protectRights/protectRights.vue')//维权服务
const ahStyle =()=> import('@/views/ahStyle/ahStyle.vue')//徽风皖韵
const ahStore =()=> import('@/views/ahStore/ahStore.vue')//徽商商城
const online =()=> import('@/views/online/online.vue')//秘书在线

const search =()=> import('@/views/search/search.vue')//搜索
const partyBuild =()=> import('@/views/partyBuild/partyBuild.vue')//党建引领






const router = new VueRouter({
  mode: 'history',

  routes :[{
    path :'/',
    component: Home,
  },
  {path:'/about', component: Abouts},
  {path:'/information', component: information},
  {path:'/member', component: member},
  {path:'/Shellone', component: Shellone},
  {path:'/college', component: college},
  {path:'/cooperation', component: cooperation},
  {path:'/Business', component: Business},
  {path:'/protectRights', component: protectRights},
  {path:'/ahStyle', component: ahStyle},
  {path:'/ahStore', component: ahStore},
  {path:'/online', component: online},
  {path:'/search', component: search},
  {path:'/partyBuild', component: partyBuild},


  ]

})

export default router
