<template>
<div class="header">
   <div class="headTop">
      <div class="wd">
        <div class="logo">
            <img src="../../assets/img/skin/logo.jpg" alt="">
        </div>
        <div class="headRig">
          <div class="search">
              <!-- <el-input placeholder="请输入内容" v-model="input" class="input-with-select">
                <el-button slot="append" icon="el-icon-search"></el-button>
              </el-input> -->
          </div>
        </div>
      </div>
   </div>
   <div class="nav">
     <div class="wd">
       <ul >
        <li :class="item.on?'on':''" v-for="(item,i) in pathList" :key="i" @click="tabcut(i)"><router-link :to="{ path: item.path , query:{id:item.id} }" replace>{{item.name}}</router-link></li>
      </ul>
     </div>
   </div>
</div> 
</template>

<script>
export default {
  name: 'home',
  props: {
    msg: String
  },
  data() {
    return {
      input: '',
      pathList:[
        {path:'/',name:'首页',on:true,id:40},
        {path:'/aboutSh',name:'关于商会',on:false,id:1},
        {path:'/information',name:'商会资讯',on:false,id:2},
        {path:'/member',name:'会员之家',on:false,id:3},
        {path:'/Shellone',name:'会员风采',on:false,id:4},
        {path:'/college',name:'商学院',on:false,id:5},
        {path:'/cooperation',name:'战略合作',on:false,id:6},
        {path:'/Business',name:'商机在握',on:false,id:7},
        {path:'/protectRights',name:'维权服务',on:false,id:8},
        {path:'/ahStyle',name:'徽风皖韵',on:false,id:9},
        {path:'/ahStore',name:'徽商商城',on:false,id:10},
        {path:'/online',name:'秘书在线',on:false,id:11},
      ]
    }
  },
  created(){
    this.getIndexDate()
    
  },
  methods:{
    tabcut(i){
      this.clearStyle()
      this.pathList[i].on = true
    },
    clearStyle(){
      let data = this.pathList
      for (let index = 0; index < data.length; index++) {
          data[index].on = false
      }
    },
    getIndexDate(){
        this.$http.get('/api/ahsh/index').then(res=>{
          let {data,code} = res.data
          if (code == 0) {
            // this.itemData = data
            this.pathList = data.menus
            let configs = JSON.stringify(data.configs)
            window.localStorage.setItem('configs',configs)
            // console.log(this.itemData)
          }
        })
      },
    
  },
  mounted(){

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped >
  .header{
    position: relative;
    background-color: #fff;
    z-index: 1;
    width: 100%;
    .headTop{
      height: 140px;
      .logo{
        float: left;
        margin-top: 35px;
        width: 496px;
        height: 67px;
        img{
          width: 100%;
          height: auto;
        }
      }
      .headRig{
        float: right;
        padding-top: 35px;
        text-align: right;
        .search {
          width: 268px;
          height: 32px;
          padding-left: 10px;
          margin-top: 21px;
        }
      }
    }
    .nav {
      height: 50px;
      position: relative;
      background-color: #004987;
      ul {
        height: auto;
        overflow: hidden;
        border-left: 1px solid #063f74;
        li{
          float: left;
          position: relative;
          width: 16.6666%;
          background: url(../../assets/img/skin/navbg.jpg) repeat-y right 0;
          a{
            display: block;
            height: 50px;
            line-height: 50px;
            color: #fff;
            text-align: center;
            font-size: 16px;
          }
        }
        li:hover, .nav ul li.on {
            background: #ba8940;
            -webkit-transition: all 0.2s ease;
            -moz-transition: all 0.2s ease;
            transition: all 0.2s ease;
        }
        .on{
          background: #ba8940;
          transition: all 0.2s ease;
        }
    }
    }
  }

</style>
