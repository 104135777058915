<template>
    <div class="footer">
        <div class="wd">
            <div class="footLink">
                <a href="/">关于商会</a><span>丨</span>
                <a href="/">商会资讯</a><span>丨</span>
                <a href="/">会员之家</a><span>丨</span>
                <a href="/">会员风采</a><span>丨</span>
                <a href="/">商学院</a><span>丨</span>
                <a href="/">战略合作</a><span>丨</span>
                <a href="/">商机在握</a><span>丨</span>
                <a href="/">维权服务</a><span>丨</span>
                <a href="/">徽风皖韵</a><span>丨</span>
                <a href="/">徽商商城</a><span>丨</span>
                <a href="/">秘书在线</a> <span>丨</span>
                <a href="/">返回首页</a>
            </div>
            <div class="copy">
                <p>版权所有：Copyright © 2022-2023 广州市安徽商会 版权所有<span class="mspan1">地址：中国广东省广州天河区黄埔大道中199号广州阳光酒店M层</span></p>
                <p>备案序号：<a target="_blank" href="https://beian.miit.gov.cn/">粤ICP备2022090544号</a></p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'home',
        props: {
            msg: String
        },
        data() {
            return {
                key: ''
            }
        },
    }
</script>

<style lang="less" scoped>
.footer {
    margin-top: 30px;
    .footLink{
        font-size: 14px;
        a{
            display: inline-block;
            color: #d4dbe5;
            margin: 0 30px;
        }
    }
    .copy {
        height: auto;
        overflow: hidden;
        margin-top: 36px;
        font-size: 12px;
        .mspan1 {
            padding: 0 21px 0 7px;
            display: inline-block;
        }
    }
}
</style>